<template>
  <RouterView />
  <!-- lodaing modal -->
  <Modal
    :is-show="isLoading"
    :is-body-only="true"
    size="sm"
  >
    <template #body>
      <Vue3Lottie :animationData="LoadingJSON" />
      <div class="h3 text-center" v-if="message !== ''">{{message}}</div>
    </template>
  </Modal>
</template>
<script>
import _ from 'lodash';
import { Vue3Lottie } from 'vue3-lottie';
import Modal from '@/components/Modal.vue';
import LoadingJSON from '@/assets/images/loading.json';
import 'vue3-lottie/dist/style.css';
import { mapActions, mapState } from 'pinia';
import { useViewportWidth, useLoadingScreen } from '@/store/index';

export default {
  components: {
    Vue3Lottie,
    Modal,
  },
  data() {
    return {
      LoadingJSON,
    };
  },
  methods: {
    ...mapActions(useViewportWidth, ['updateViewportWidth']),
  },
  computed: {
    ...mapState(useLoadingScreen, {
      isLoading: 'isLoading',
      message: 'message',
    }),
  },
  mounted() {
    const setViewportToStore = _.throttle(() => {
      this.updateViewportWidth(document.documentElement.clientWidth);
    }, 500);
    setViewportToStore();
    window.addEventListener('resize', setViewportToStore);
  },
};
</script>
