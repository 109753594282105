import { defineStore } from 'pinia';

export const useViewportWidth = defineStore('viewport', {
  state: () => ({ viewportWidth: 0 }),
  actions: {
    updateViewportWidth(value) {
      this.viewportWidth = value;
    },
  },
});

export const useLoadingScreen = defineStore('loading', {
  state: () => ({
    isLoading: false,
    message: '',
  }),
  actions: {
    setLoading(isLoading, message = '') {
      this.isLoading = isLoading;
      this.message = isLoading ? message : '';
    },
  },
});
