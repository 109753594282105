import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: 'error',
  },
  {
    path: '/',
    redirect: 'login/',
    component: () => import('../views/Layout/Home.vue'),
    children: [
      {
        path: 'login/',
        name: '會員登入',
        meta: { requiresAuth: false },
        component: () => import('../views/Home/Login.vue'),
      },
      {
        path: 'forgetpassword/',
        name: '忘記密碼',
        meta: { requiresAuth: false },
        component: () => import('../views/Home/ForgotPassword.vue'),
      },
      {
        path: 'resetpassword/',
        name: '重設密碼',
        meta: { requiresAuth: false },
        component: () => import('../views/Home/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('../views/Layout/Page.vue'),
    children: [
      {
        path: 'signup/',
        name: '會員申請',
        meta: { requiresAuth: false },
        component: () => import('../views/SignUp.vue'),
      },
      {
        path: 'schedule/',
        name: '檔期行事曆',
        meta: { requiresAuth: false },
        component: () => import('../views/Schedule.vue'),
      },
      {
        path: 'user/',
        name: '會員資料',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/Profile.vue'),
      },
      {
        path: 'user/schedule',
        name: '我的行事曆',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/UserSchedule.vue'),
      },
      {
        path: 'user/edit',
        name: '會員資料修改',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/EditProfile.vue'),
      },
      {
        path: 'user/password',
        name: '密碼管理',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/EditorPassword.vue'),
      },
      {
        path: 'user/project/',
        name: '申請案件總表',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/ProjectSheet.vue'),
      },
      {
        path: 'user/project/concert/:id',
        name: '案件資訊 ',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/ProjectDetailConcert.vue'),
      },
      {
        path: 'user/project/cultural/:id',
        name: '案件資訊',
        meta: { requiresAuth: true },
        component: () => import('../views/Member/ProjectDetailCultural.vue'),
      },

      {
        path: 'rent/concert',
        name: '表演廳檔期申請',
        meta: { requiresAuth: true, leavePrevent: true },
        component: () => import('../views/Form/ConcertRent.vue'),
      },
      {
        path: 'rent/cultural',
        name: '文化館檔期申請',
        meta: { requiresAuth: true, leavePrevent: true },
        component: () => import('../views/Form/CulturalRent.vue'),
      },
    ],
  },
  {
    path: '/error',
    component: () => import('../views/Layout/ErrorPage.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const token = localStorage.getItem('token');
  // if (from.meta.leavePrevent) {
  //   return window.confirm('表單內容將不會儲存');
  // }

  // 已登入且位於首頁，導向至 projects
  if (to.path === '/login/') {
    if (token) {
      router.push('/user/project');
    }
  }

  // 檢查登入
  if (to.meta.requiresAuth) {
    if (!token) {
      // 未登入
      alert('請先登入會員');
      router.push('/login');
    }
  }

  return true;
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    window.scrollTo(0, 0);
  }
});

export default router;
