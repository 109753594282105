import { createApp, markRaw } from 'vue';
import Popper from 'vue3-popper';
import { SetupCalendar } from 'v-calendar';
import moment from 'moment';
import { createPinia } from 'pinia';
import VueGtag from 'vue-gtag';
import helper from './utils/helper';
import App from './App.vue';
import router from './router';
import clickOutside from './utils/directive';

import 'v-calendar/dist/style.css';
import './assets/scss/common.scss';
import './assets/scss/main.scss';

const app = createApp(App);
// pinia
const pinia = createPinia();
pinia.use(({ store }) => { store.router = markRaw(router); }); /* eslint-disable-line */

// moment js
moment.locale('zh-tw');
app.config.globalProperties.$moment = moment;

app.config.unwrapInjectedRef = true;

app
  .mixin(helper)
  .use(VueGtag, {
    config: { id: 'G-53M7YBZPPT' },
  })
  .component('Popper', Popper)
  .directive('click-outside', clickOutside)
  .use(SetupCalendar, {})
  .use(router)
  .use(pinia)
  .mount('#app');
