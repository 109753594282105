import _ from 'lodash';
import moment from 'moment';

// [NEED REFACTOR]: helper 實為 mixin
// 必須拆分 mixin.js(給vue注入) 與 helper.js(給任何js引入)
const helper = {
  methods: {
    todayDateObj() {
      return moment().startOf('day').toDate();
    },
    dateStringToTimeStamp(dateString, isStartOfDay = true) {
      const method = isStartOfDay ? 'startOf' : 'endOf';
      return moment(dateString)[method]().unix() * 1000;
    },
    dateDuration(startDate, endDate) {
      return moment.duration((moment(endDate)).diff(moment(startDate))).asDays() + 1;
    },
    dateDurationToArray(startDate, endDate) {
      const durationArray = endDate ? _.range(this.dateDuration(startDate, endDate)) : [0];
      return _.map(durationArray, (duration) => ({ startDate: moment(startDate).add(duration, 'day').format('YYYY-MM-DD') }));
    },
    dateOffset(date, offsetDayCount) {
      return date ? moment(date).add(offsetDayCount, 'day').format('YYYY-MM-DD') : null;
    },
    thousandsFormatter(number) {
      return number ? number.toLocaleString('zh-Hant') : null;
    },
    datetimeStringToDateString(dateString) {
      if (_.isString(dateString) && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(dateString)) {
        return dateString.split('T')[0];
      }
      return dateString;
    },
    chargeFormatter(chargeData) {
      return _.map(chargeData, (data) => {
        const { receiveDate, expectedDate } = data;

        const chargeStatusCheck = _.cond([
          [(receive) => _.isEmpty(receive), () => 1], // 未繳納
          [(receive, expected) => moment(receive).isSameOrBefore(expected), () => 2], // 已繳納
          [(receive, expected) => moment(receive).isAfter(expected), () => 3], // 已逾期
          [() => true, () => 4], // 其他未知情況
        ]);

        const formatData = {
          ...data,
          chargeStatus: chargeStatusCheck(receiveDate, expectedDate),
        };

        return formatData;
      });
    },
  },
};

export default helper;
