<template>
  <Transition name="fade">
    <div v-if="isShow" class="modal" :class="className" @click.self="$emit('close')">
        <div class="modal-container">
          <div class="modal-header" v-if="!isBodyOnly">
            <slot name="header"></slot>
            <button
              type="button"
              class="modal-header-close"
              @click.prevent="$emit('close')"
              ></button>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer" v-if="!isBodyOnly">
            <slot name="footer"></slot>
          </div>
        </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    isBodyOnly: {
      type: Boolean,
      default: false,
    },
    size: String,
  },
  computed: {
    className() {
      const { size } = this;
      return size ? `modal-${size}` : '';
    },
  },
};
</script>
